import React, { useState } from 'react';
import { space300 } from '@ingka/variables/design-tokens';
import { useT } from 'src/hooks/intl';
import { Container, Title } from './../styles';
import { Button, Modal, ModalHeader, Sheets, Toast } from 'src/skapa';
import { ToastProps } from '@ingka/toast';
import { useErrors, Errors } from './../ErrorMessage';
import { useIntl } from 'src/hooks/intl';
import {
  GetRegistryDocument,
  useChangeGiftBagItemsQuantityMutation,
  GiftBag,
  GiftBagItem,
} from 'src/generated/graphql';
import { splitPayload } from 'src/utils/handleUnions';

import { GiftItem } from './../GiftIBagtem';
import { DeleteItemModal } from './DeleteItemModal';

interface EditOrderModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  giftBag: GiftBag;
  onDeleteGiftBag: () => void;
}

export const EditOrderModal: React.FC<EditOrderModalProps> = ({
  setOpen,
  open,
  giftBag,
  onDeleteGiftBag,
}) => {
  const t = useT();
  const { language } = useIntl();
  const [toastSettings, setToastSettings] = useState<ToastProps>({
    isOpen: false,
    text: '',
  });
  const [removedItemNumbers, setRemovedItemNumbers] = useState<string[]>([]);

  const [changeGiftBagItemsQuantityMutation] =
    useChangeGiftBagItemsQuantityMutation();

  let stepperDebounce: ReturnType<typeof setTimeout>;
  const onQuantityChange = (qty: number, item: GiftBagItem) => {
    clearTimeout(stepperDebounce);
    stepperDebounce = setTimeout(() => {
      onChangeQuantity(qty, item);
    }, 1000);
  };

  const onChangeQuantity = async (quantity: number, item: GiftBagItem) => {
    const { data } = await changeGiftBagItemsQuantityMutation({
      variables: {
        changeGiftBagItemsQuantityInput: {
          giftBagId: giftBag.id,
          items: [{ itemNo: item.itemNo, quantity }],
          registryId: giftBag.registryId,
        },
        languageCode: language,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GetRegistryDocument,
          variables: {
            languageCode: language,
            input: { registryId: giftBag.registryId },
          },
        },
      ],
    });

    const [, partialError] = splitPayload(
      'ChangeGiftBagItemsQuantityPayload',
      data?.changeGiftBagItemsQuantity
    );

    if (partialError) {
      addError(t(`error.generic.${partialError}`));
    } else {
      setToastSettings({
        isOpen: true,
        text: t('order.editOrderModal.quantityUpdated', {
          quantity: quantity,
          productName: item.product?.productName,
        }),
      });
    }
  };

  const { addError, errorsProps } = useErrors();

  const [deleteGiftBagItem, setDeleteGiftBagItem] = useState<{
    isModalOpen: boolean;
    giftBagId: string | undefined;
    itemNo: string | undefined;
  }>({ isModalOpen: false, giftBagId: undefined, itemNo: undefined });
  return (
    <>
      <Modal
        visible={open}
        escapable
        handleCloseBtn={() => setOpen(false)}
        onModalClosed={() => setRemovedItemNumbers([])}
      >
        <Sheets footer={null} header={<ModalHeader />}>
          <Errors {...errorsProps} />
          <Container
            marginBottom={space300}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Title component="h2" variant="h2">
              {t('order.editOrderModal.title')}
            </Title>
            <Button
              text={t('order.editOrderModal.delete')}
              small
              type="danger"
              onClick={() => onDeleteGiftBag()}
            />
          </Container>
          {giftBag.gifts
            .filter((x) => !removedItemNumbers.includes(x.itemNo))
            .map((giftBagItem) => {
              return (
                <GiftItem
                  gift={giftBagItem}
                  giftBagId={giftBag.id}
                  key={giftBagItem.itemNo}
                  onDeleteGiftBagItem={(itemNo: string) => {
                    setDeleteGiftBagItem({
                      isModalOpen: true,
                      giftBagId: giftBag.id,
                      itemNo,
                    });
                  }}
                  onQuantityChange={(quantity: number) => {
                    if (giftBagItem.quantity !== quantity) {
                      onQuantityChange(quantity, giftBagItem);
                    }
                  }}
                />
              );
            })}
        </Sheets>
      </Modal>
      <DeleteItemModal
        giftBagId={deleteGiftBagItem.giftBagId || ''}
        itemNo={deleteGiftBagItem.itemNo || ''}
        visible={deleteGiftBagItem.isModalOpen}
        onClose={() =>
          setDeleteGiftBagItem({
            isModalOpen: false,
            giftBagId: undefined,
            itemNo: undefined,
          })
        }
      />
      <Toast
        {...toastSettings}
        onCloseRequest={() =>
          setToastSettings({ ...toastSettings, isOpen: false })
        }
      />
    </>
  );
};
